<template>
  <div class="web_box">
    <van-nav-bar
      title="收货地址"
      left-arrow
      @click-left="toBack"
      fixed
      class="header_class"
      :border="false"
      @click-right="toAdd"
    >
      <div class="back_icon" slot="left" />
      <div class="add_icon" slot="right" v-if="empty" />
    </van-nav-bar>
    <div class="empty_box" v-if="!empty">
      <p>暂无收货地址，快去新建地址~</p>
      <button @click="toAdd">新建收货地址</button>
    </div>
    <div class="address_list" v-else>
      <van-swipe-cell v-for="item in addressList" :key="item.id">
        <div class="address_item" @click.stop="change(item)">
          <div
            class="checked_icon"
            :class="item.type == 0 ? 'checked_true' : 'checked_false'"
          ></div>
          <div class="address_details">
            <p>
              {{ item.contactName }} <span>{{ item.contactPhone }}</span>
            </p>
            <i class="defaultTag" v-show="item.type == 0">默认地址</i>
            <span>收货地址：{{ item.addressDetail }}</span>
          </div>
          <div class="edit" @click.stop="toEdit(item)"></div>
        </div>
        <van-divider class="divider_line" />
        <template #right>
          <van-button
            @click="delAddrItem(item)"
            square
            type="danger"
            class="delete-button"
          />
        </template>
      </van-swipe-cell>
    </div>
  </div>
</template>
<script>
import {queryAddrList, delAddr, setDefault} from "@/utils/api";
import {Toast} from "vant";

export default {
  data() {
    return {
      empty: true,
      addressList: [],
    };
  },
  mounted() {
    this.getAddress();
  },
  methods: {
    // 获取地址列表
    getAddress() {
      queryAddrList()
        .then((res) => {
          this.addressList = res.data.addressList;
          this.addressList.length == 0
            ? (this.empty = false)
            : (this.empty = true);
          console.log(res.data.addressList.length);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toAdd() {
      this.$router.push({path: "/editAddress", query: {type: 1}});
    },
    toEdit(item) {
      let params = JSON.stringify(item);
      this.$router.push({path: "/editAddress", query: {type: 0, item: params}});
    },
    change(item) {
      setDefault({
        addressId: item.id,
      })
        .then(() => {
          Toast.success("已设置默认");
          this.getAddress();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除
    delAddrItem(item) {
      Toast.loading({
        message: "正在删除...",
        forbidClick: true,
        duration: 500,
      });
      delAddr({
        addressId: item.id,
      })
        .then((res) => {
          Toast.success(res.msg);
          this.getAddress();
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.empty_box {
  width: 100%;
  text-align: center;
  p {
    color: #8cbcd6;
    margin-top: 241px;
    font-size: 26px;
    text-align: center;
  }
  button {
    width: 90%;
    background: #00ccff;
    border-radius: 44px;
    height: 88px;
    text-align: center;
    border: none;
    outline: none;
    color: #fff;
    margin: 278px auto 0;
  }
}
.address_list {
  margin-top: 108px;
  width: 100%;
}
.address_item {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 30px;
  background-color: #12172c;
  .checked_icon {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .checked_true {
    background-image: url("../../assets/address/checked_true.png");
  }
  .checked_false {
    background-image: url("../../assets/address/checked_false.png");
  }
  .address_details {
    margin-left: 28px;
    width: 614px;
    position: relative;
    p {
      color: #00ccff;
      font-size: 26px;
      margin-bottom: 11px;
      span {
        margin-left: 30px;
      }
    }
    span {
      color: #7f89b3;
      font-size: 26px;
    }
    .defaultTag {
      position: absolute;
      padding: 6px 26px;
      background-color: #232a44;
      border-radius: 123px;
      font-size: 20px;
      top: 0;
      color: #8cbcd6;
      font-style: normal;
      right: 60px;
    }
  }
  .edit {
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../assets/address/edit_icon.png");
  }
}
.delete-button {
  width: 100px;
  height: 100%;
  background-color: #ff6666;
  background-image: url("../../assets/address/del_icon.png");
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: center;
}
.divider_line {
  margin: 0 30px;
  background-color: #1e475d;
  //   padding: 0 30px;
  color: #1e475d;
  border-color: #1e475d;
}
// 1E475D
</style>
